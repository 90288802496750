import { Route, Routes } from "react-router-dom";

import NavBar from './nav-bar';

import Home from './pages/home';
import WhatWeOffer from './pages/what-we-offer';
import ProductItem from "./components/products/product-item";
import About from './pages/about';
import Contact from "./pages/contact";

import Footer from './pages/footer';

import './index.css';
import './mobile.css';

function App() {
  return (
    <div className='page-container'>
      <NavBar />
      <div className='container'>
        <Routes>
          <Route path="/" element={<Home />}  />
          <Route path="/what-we-offer/:product" element={<ProductItem />} />
          <Route path="/what-we-offer" element={<WhatWeOffer />} />
          <Route path="/about" element={<About />}  />
          <Route path="/contact" element={<Contact />}  />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
