export const laserImages = [
    '/img/lasering-examples/0419222220.jpg',
    '/img/lasering-examples/0419222221.jpg',
    '/img/lasering-examples/0425222126.jpg',
    '/img/lasering-examples/0425222128.jpg',
    '/img/lasering-examples/0425222128_HDR.jpg',
    '/img/lasering-examples/0502222003.jpg',
    '/img/lasering-examples/0505220728.jpg',
    '/img/lasering-examples/0505220728b.jpg',
    '/img/lasering-examples/0927222221.jpg',
    '/img/lasering-examples/0927222221_HDR.jpg',
    '/img/lasering-examples/1005222038_HDR.jpg',
    '/img/lasering-examples/1008221726.jpg',
    '/img/lasering-examples/1108222010a.jpg',
    '/img/lasering-examples/1108222023_HDR.jpg',
    '/img/lasering-examples/1201222139_HDR.jpg',
    '/img/lasering-examples/earring-display.jpg',
    '/img/lasering-examples/earring-display-2.jpg',
    '/img/lasering-examples/earring-display-3.jpg',
    '/img/lasering-examples/good-hair-vibes.jpg',
    '/img/lasering-examples/hermosa-studios.jpg',
    '/img/lasering-examples/keychain-display.jpg',
    '/img/lasering-examples/keychain-display-2.jpg',
    '/img/lasering-examples/master-mechanics.jpg',
    '/img/lasering-examples/medals.jpg',
    '/img/lasering-examples/PXL_20231021_114728996.jpg',
    '/img/lasering-examples/PXL_20231022_011509465.jpg',
    '/img/lasering-examples/PXL_20231110_012721206.jpg',
    '/img/lasering-examples/PXL_20231120_201248483.jpg',
    '/img/lasering-examples/PXL_20240308_022223473.jpg',
    '/img/lasering-examples/PXL_20240308_022230624.jpg',
    '/img/lasering-examples/PXL_20240413_220841178.jpg',
    '/img/lasering-examples/PXL_20240413_220848051.jpg',
    '/img/lasering-examples/PXL_20240513_161530474.jpg',
    '/img/lasering-examples/PXL_20240513_161537322.MP.jpg',
    '/img/lasering-examples/PXL_20240515_013523360.jpg',
    '/img/lasering-examples/PXL_20240515_015758845.MP.jpg',
    '/img/lasering-examples/PXL_20240516_011945301.jpg',
    '/img/lasering-examples/PXL_20240516_022617977.jpg',
    '/img/lasering-examples/PXL_20240516_022759891.jpg',
    '/img/lasering-examples/PXL_20240606_033124604.jpg',
    '/img/lasering-examples/PXL_20240612_034412354.jpg',
    '/img/lasering-examples/PXL_20240615_003922210.jpg',
    '/img/lasering-examples/PXL_20240615_010805977.jpg',
];

export const sublimationImages = [
    '/img/product-types/sublimation/fuelled-by-coffee-tumbler.jpg',
    '/img/product-types/sublimation/i-got-it-from-my-mama-tumbler.jpg',
    '/img/product-types/sublimation/keychain-sublimation-1.jpg',
    '/img/product-types/sublimation/life-is-better-at-the-beach-tumbler.jpg',
    '/img/product-types/sublimation/mermaid-tumbler.jpg',
    '/img/product-types/sublimation/monster-truck-tumbler.jpg',
    '/img/product-types/sublimation/mug-1.jpg',
    '/img/product-types/sublimation/animal-skull-tumbler.jpg',
    '/img/product-types/sublimation/dinosaur-tumbler.jpg',
    '/img/product-types/sublimation/earrings-1.jpg',
];

export const doorHangerImages = [
    "/img/product-types/door-hangers-and-wreaths/door-hangers-wreaths-1.jpg",
    "/img/product-types/door-hangers-and-wreaths/door-hangers-wreaths-2.jpg",
    "/img/product-types/door-hangers-and-wreaths/door-hangers-wreaths-3.jpg",
    "/img/product-types/door-hangers-and-wreaths/door-hangers-wreaths-4.jpg",
    "/img/product-types/door-hangers-and-wreaths/door-hangers-wreaths-5.jpg",
    "/img/product-types/door-hangers-and-wreaths/door-hangers-wreaths-6.jpg",
];

export const crochetImages = [
    "/img/product-types/crochet-designs/crochet-designs-12.jpg",
    "/img/product-types/crochet-designs/crochet-designs-13.jpg",
    "/img/product-types/crochet-designs/crochet-designs-14.jpg",
    "/img/product-types/crochet-designs/crochet-designs-15.jpg",
    "/img/product-types/crochet-designs/crochet-designs-16.jpg",
    "/img/product-types/crochet-designs/sea-horse-crochet.jpg",
    "/img/product-types/crochet-designs/turtles-crochet.jpg",
    "/img/product-types/crochet-designs/crochet-designs-1.jpg",
    "/img/product-types/crochet-designs/crochet-designs-2.jpg",
    "/img/product-types/crochet-designs/crochet-designs-3.jpg",
    "/img/product-types/crochet-designs/crochet-designs-4.jpg",
    "/img/product-types/crochet-designs/crochet-designs-5.jpg",
    "/img/product-types/crochet-designs/crochet-designs-6.jpg",
    "/img/product-types/crochet-designs/crochet-designs-7.jpg",
    "/img/product-types/crochet-designs/crochet-designs-8.jpg",
    "/img/product-types/crochet-designs/crochet-designs-9.jpg",
    "/img/product-types/crochet-designs/crochet-designs-10.jpg",
    "/img/product-types/crochet-designs/crochet-designs-11.jpg"
];

export const cricutImages = [
    "/img/product-types/cricut-designs/cricut-designs-1.jpg",
    "/img/product-types/cricut-designs/cricut-designs-2.jpg",
    "/img/product-types/cricut-designs/cricut-designs-3.jpg",
    "/img/product-types/cricut-designs/cricut-designs-4.jpg",
    "/img/product-types/cricut-designs/cricut-designs-5.jpg",
    "/img/product-types/cricut-designs/cricut-designs-6.jpg",
    "/img/product-types/cricut-designs/cricut-designs-7.jpg",
    "/img/product-types/cricut-designs/cricut-designs-8.jpg",
    "/img/product-types/cricut-designs/cricut-designs-9.jpg",
    "/img/product-types/cricut-designs/cricut-designs-10.jpg"
];