import TheTeam from "../components/the-team/the-team";
import TheTools from "../components/the-tools/the-tools";

export default function About() {
    return (
        <section className="page-content-container">
            <h1 className="page-header">About</h1>
            <TheTeam />
            <TheTools />
        </section>
    );
}