import React, { useState, useEffect, useCallback } from 'react';
import './image-slider.css';

const ImageGallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);

  // Function to go to the next image (wrapped in useCallback)
  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  // Function to go to the previous image (wrapped in useCallback)
  const prevImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images.length]);

  // Effect for automatic image transition every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextImage, 5000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [currentIndex, prevImage, nextImage]);

  // Touch event handlers
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const threshold = 50;

    if (touchStartX - touchEndX > threshold) {
      // Swipe left
      nextImage();
    } else if (touchEndX - touchStartX > threshold) {
      // Swipe right
      prevImage();
    }
  };

  return (
    <div 
      className="image-gallery"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="image-container">
        <img
          src={images[currentIndex]}
          alt={`Laser Cutting/Engraving example ${currentIndex + 1}`}
        />
      </div>
      <div className="pagination-controls">
        <button onClick={prevImage} className="button-blue">&lt;</button>
        <span>{currentIndex + 1} of {images.length}</span>
        <button onClick={nextImage} className="button-blue">&gt;</button>
      </div>
    </div>
  );
};

export default ImageGallery;
