import ImageGallery from "../../image-slider"
import { cricutImages } from "../../../helpers/image-files"

export default function Cricut() {
    return (
        <>
            <ImageGallery images={cricutImages} />
            <p><strong>Creativity Unleashed with Cricut:</strong> Our Cricut designs open up a world of possibilities for your crafting projects. Utilizing the power and precision of a Cricut machine, we offer a wide range of designs that can be cut, embossed, or drawn onto various materials. From vinyl decals to intricate paper cut-outs, our Cricut designs allow you to personalize home decor, create custom apparel, design unique invitations, and much more. Let your imagination soar and bring your creative ideas to life with our collection of Cricut designs.</p>
        </>
    );
}