import ImageGallery from "../../image-slider"
import { doorHangerImages } from "../../../helpers/image-files";

export default function DoorWreathsHangers() {
    return (
        <>
            <ImageGallery images={doorHangerImages} />
            <p><strong>Door Hangers:</strong> Charming Door Hangers: Our door hangers are delightful accessories designed to add personality and charm to any entryway. Available in various materials and styles, our handcrafted door hangers feature unique designs, vibrant colors, and intricate details. From seasonal themes to personalized messages, these eye-catching pieces are perfect for welcoming guests and expressing your individual style.</p><p><strong>Beautiful Wreaths for Every Occasion:</strong> Our wreaths are meticulously crafted with attention to detail, bringing beauty and elegance to any space. Whether it\'s for a front door, wall, or tabletop, our wreaths feature an array of materials such as dried flowers, foliage, ribbons, and embellishments. From holiday-themed wreaths to personalized designs, each creation adds a welcoming touch to your home or serves as a thoughtful gift for special occasions. Experience the timeless beauty and versatility of our handcrafted wreaths.</p>
        </>
    );
}