import './the-team.css';

export default function TheTeam() {
    return (
        <section className='the-team-container'>
            <article className="mission-statement">
                <p>At Cattle Dog Creations, a small family run crafting business, our mission is to create beautiful, handmade crafts that bring joy, inspire creativity, and foster meaningful connections.</p>
                <p>Our vision goes beyond the mere creation of crafts; we aim to cultivate a vibrant community of artisans, craft enthusiasts, and customers who appreciate the value of handmade goods. We believe in the power of crafting to uplift spirits, promote well-being, and encourage self-expression.</p>
                <p>Customer satisfaction lies at the core of our business. We aim to provide a personalized and exceptional experience for every customer, taking the time to understand their unique needs and preferences. By offering a wide range of customizable options, we strive to create pieces that reflect our customers' individual style and personality, resulting in cherished keepsakes that can be passed down through generations.</p>
                <p>At Cattle Dog Creations, we are driven by our passion for crafting and the belief that handmade creations can enrich lives, create meaningful connections, and foster a sense of belonging. We invite you to join us on this artistic journey and experience the magic of truly handmade crafts.</p>
            </article>

            <h2 className="page-sub-header">Meet the Team</h2>
            <section className="team-members">
                <article className='team-member'>
                    <div className="team-member-details">
                        <img src="img/kaity2.jpg" alt="Kaity" width="300" height="300" className="team-member-image" />
                        <span className="team-member-name">Kaity</span>
                    </div>
                        <span className="team-member-bio">Master of the Cricut. Kaity has been crafting for friends, family and selling on FB Marketplace.</span>
                </article>
                <article className='team-member'>
                    <div className="team-member-details">
                        <img src="img/sarah2.jpg" alt="Sarah" width="300" height="300" className="team-member-image" />
                        <span className="team-member-name">Sarah</span>
                    </div>
                        <span className="team-member-bio">Master of the crochet. Sarah has been crafting, sewing and crocheting for many years.</span>
                </article>
                <article className='team-member'>
                    <div className="team-member-details">
                        <img src="img/paul2.jpg" alt="Paul" width="300" height="300" className="team-member-image" />
                        <span className="team-member-name">Paul</span>
                    </div>
                        <span className="team-member-bio">The laser cut & engraving member of the team. Paul also is the one tasked with the website and online Etsy store.</span>
                </article>
            </section>
        </section>
    );
}