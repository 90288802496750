import { Link, useParams } from "react-router-dom";

function CustomLink({ to, children, ...props }) {
    const product = useParams().product;
    const productUrl = `/what-we-offer/${product}`

    return (
        <li className={productUrl === to ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}

export default function ProductSideNav() {
    return (
        <span className="products-sidenav">
            <ul>
                <CustomLink to="/what-we-offer/sublimation">Sublimation</CustomLink>
                <CustomLink to="/what-we-offer/crochet">Crochet</CustomLink>
                <CustomLink to="/what-we-offer/door-hangers-and-wreaths">Door Hangers & Wreaths</CustomLink>
                <CustomLink to="/what-we-offer/cricut">Cricut</CustomLink>
                <CustomLink to="/what-we-offer/laser">Laser</CustomLink>
                <CustomLink to="/what-we-offer/custom">Custom</CustomLink>
            </ul>
            {/* <ContactForm /> */}
        </span>
    );
}