import ProductExamples from '../components/products/product-examples';

import '../css/home.css';

export default function Home() {
    return (
        <section className='page-content-container'>
            <section className="home-welcome">
                <div className="home-welcome-image">
                    <img src="/img/tall-tumbler-customize.jpg" width="750" height="500" alt="we customize tumblers" />
                </div>
                <div className="home-welcome-description">
                    <h1 className="page-header">Welcome to Cattle Dog Creations</h1>
                    <p className="home-subtitle">Where our mission is to create beautiful, handmade crafts that bring joy.</p>
                </div>
            </section>            
            
            <h2 className="product-header">What can <span>WE</span><br />
            do for <span>YOU?</span></h2>
            
            <ProductExamples />
        </section>
    );
}