import { Link } from 'react-router-dom';

import './product-examples.css';

export default function ProductExamples() {
    return (
        <article className="product-examples">
            <div className="product-example right">                
                    <div className="product-description right">
                        <h3 className="page-section-header">Sublimation</h3>
                        <p><strong>Vibrant Sublimated Products:</strong> Our sublimation offerings encompass a range of products that feature vibrant and high-resolution designs. With sublimation, we can create beautifully customized T-shirts, keychains, mugs, tumblers, sippy cups, and even adult and child tumbler sets. Using a special heat transfer process, our sublimation products boast long-lasting colors and stunning details that make a statement. Each item can be personalized with unique designs, logos, or photos, making them perfect for gifts, promotions, or cherished personal mementos.</p>
                        <p className="back-button-wrapper"><Link to="/what-we-offer/sublimation" className="back-button">Find out more</Link></p>
                    </div>
                    <div className="product-image sublimation" />
            </div>
            <div className="product-example left">
                    <div className="product-image laser" />
                    <div className="product-description left">
                        <h3 className="page-section-header">Laser Cut</h3>
                        <p><strong>Precision Laser Cut Creations:</strong> Explore our collection of laser cut designs that showcase intricate artistry and precision craftsmanship. From wooden art pieces to paper decorations and acrylic jewelry, each creation is a testament to the beauty of laser cutting technology, adding sophistication and elegance to any space or outfit.</p><p><strong>Personalized Laser Engraved Creations:</strong>  Our laser engraved designs offer a touch of sentimentality and elegance to various products. From photo frames capturing special memories to personalized drinkware and home decor pieces, each item is meticulously engraved with custom text, designs, or messages, adding a unique and personalized touch to everyday items and special keepsakes.</p>
                        <p className="back-button-wrapper"><Link to="/what-we-offer/laser" className="back-button">Find out more</Link></p>
                    </div>
            </div>
            <div className="product-example right">
                    <div className="product-description right">
                        <h3 className="page-section-header">Cricut</h3>
                        <p><strong>Creativity Unleashed with Cricut:</strong> Our Cricut designs open up a world of possibilities for your crafting projects. Utilizing the power and precision of a Cricut machine, we offer a wide range of designs that can be cut, embossed, or drawn onto various materials. From vinyl decals to intricate paper cut-outs, our Cricut designs allow you to personalize home decor, create custom apparel, design unique invitations, and much more.</p>
                        <p className="back-button-wrapper"><Link to="/what-we-offer/cricut" className="back-button">Find out more</Link></p>
                    </div>
                    <div className="product-image cricut" />
            </div>
            <div className="product-example left">
                    <div className="product-image door-hangers-wreaths" />
                    <div className="product-description left">
                        <h3 className="page-section-header">Door Hangers & Wreaths</h3>
                        <p><strong>Charming Door Hangers:</strong> Our door hangers are delightful accessories designed to add personality and charm to any entryway. Available in various materials and styles, our handcrafted door hangers feature unique designs, vibrant colors, and intricate details.</p>
                        <p><strong>Beautiful Wreaths for Every Occasion:</strong> Our wreaths are meticulously crafted with attention to detail, bringing beauty and elegance to any space. Our wreaths feature an array of materials such as dried flowers, foliage, ribbons, and embellishments.</p>
                        <p className="back-button-wrapper"><Link to="/what-we-offer/door-hangers-and-wreaths" className="back-button">Find out more</Link></p>
                    </div>
            </div>
            <div className="product-example right">
                    <div className="product-description right">
                        <h3 className="page-section-header">Crochet</h3>
                        <p><strong>Crochet Plushies:</strong> Our lovingly made crochet plushies are huggable companions designed to bring joy to children and adults alike. From charming animal plushies to whimsical fantasy creatures, each piece is crafted with care and attention to detail, making them perfect for gifting or as treasured keepsakes.</p>
                        <p className="back-button-wrapper"><Link to="/what-we-offer/crochet" className="back-button">Find out more</Link></p>
                    </div>
                    <div className="product-image crochet" />
            </div>
            <div className="product-example left">
                    <div className="product-image custom" />
                    <div className="product-description left">
                        <h3 className="page-section-header">Custom</h3>
                        <p><strong>Tailored Creations Just for You:</strong> Embrace the power of personalization with our custom items. Whether you have a specific vision in mind or need a one-of-a-kind gift, our bespoke creations are tailored to meet your unique preferences. From custom-engraved jewelry to personalized home decor and bespoke apparel, we work closely with you to bring your ideas to life.</p>
                        <p className="back-button-wrapper"><Link to="/what-we-offer/custom" className="back-button">Find out more</Link></p>
                    </div>
            </div>
        </article>
    );
}