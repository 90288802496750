import { Link } from "react-router-dom";
import ImageGallery from "../../image-slider";
import { laserImages } from "../../../helpers/image-files";

export default function Laser() {
    return (
        <div className="product-content">
            {/* <p><strong>Precision Laser Cut Creations:</strong> Explore our collection of laser cut designs that showcase intricate artistry and precision craftsmanship. From wooden art pieces to paper decorations and acrylic jewelry, each creation is a testament to the beauty of laser cutting technology, adding sophistication and elegance to any space or outfit.</p> */}
            
            {/* <p><strong>Personalized Laser Engraved Creations:</strong>  Our laser engraved designs offer a touch of sentimentality and elegance to various products. From photo frames capturing special memories to personalized drinkware and home decor pieces, each item is meticulously engraved with custom text, designs, or messages, adding a unique and personalized touch to everyday items and special keepsakes.</p> */}


            <p>From one-off custom designs to batch products for corporate events and teams, we at Cattle Dog Creations can help. Just <Link to="/contact" className="link">contact us</Link> and let us know how we can help you.</p>

            <ImageGallery images={laserImages} />
        </div>
    );
}