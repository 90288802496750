import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { BsArrowUpRightSquare, BsFacebook, BsInstagram } from 'react-icons/bs';

import './css/nav-bar.css';

export default function NavBar() {
    return (
        <div className="nav-container">
            <nav className="nav">
                <div className="logo-socials">
                    <Link to="/" className="site-title">
                        <img src="/img/cattle-dog-creations-bandw-blacktext.png" width="60" height="60" alt="Cattle Dog Creations" aria-describedby="Cattle Dog Creations Logo" />
                        Cattle Dog Creations
                    </Link>
                    <ul>
                        <CustomLink to="/">Home</CustomLink>
                        <CustomLink to="/about">About Us</CustomLink>
                        <CustomLink to="/what-we-offer">What We Offer</CustomLink>
                        <CustomLink to="/contact">Contact Us</CustomLink>
                    </ul>
                    <span className="socials">
                        <a 
                            href="https://www.facebook.com/kaitskorner757"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                                <BsFacebook />
                        </a>
                        <a 
                            href="https://www.instagram.com/cattledogcreations757/"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                                <BsInstagram />
                        </a>
                        <a 
                            href="https://www.etsy.com/shop/CDCreationsOnline" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            Etsy Store <BsArrowUpRightSquare className="external-link-icon" title="External Link to Cattle Dog Creations Online Etsy.com" />
                        </a>
                    </span>
                </div>
            </nav>
        </div>
    );
};

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({path: resolvedPath.pathname, end: true});

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}