import './the-tools.css';

export default function TheTools() {
    return (
        <section className='the-tools-container'>
            <h2 className="page-sub-header">What We Use</h2>
            <section className="teams">
                <article className='tool-details'>
                    <img src="img/tools-we-use/brother-sp-1-sublimation-printer.jpg" alt="Brother SP1 Sublimation Printer" width="679" height="408" className="tool-image" />
                    <span className="tool-name">
                        <strong>Brother SP1 Sublimation Printer</strong><br />
                        Our go to for printing all our sublimation designs.
                    </span>
                </article>
                <article className='tool-details reverse'>
                    <img src="img/tools-we-use/HTVRONT-15x15-Auto-Heat-Press-Machine.jpg" alt="HTVRONT 15x15 Heat Press" width="679" height="495" className="tool-image" />
                    <span className="tool-name">
                        <strong>HTVRONT 15x15 Heat Press</strong><br />
                        Our main press for t-shirts, mugs, rugs and all manner of flat items.
                    </span>
                </article>
                <article className='tool-details'>
                    <img src="img/tools-we-use/transpro-tumbler-press.jpg" alt="Transpro Plus Tumbler Mug Press" width="1000" height="1000" className="tool-image" />
                    <span className="tool-name">
                        <strong>Transpro Plus Tumbler Mug Press</strong><br />
                        Our main go to for tumblers, mugs and other cylindrical items.
                    </span>
                </article>
                <article className='tool-details reverse'>
                    <img src="img/tools-we-use/PYD-Life-Sublimation-Oven-Machine-25L-1600W.jpg" alt="PYD Life Sublimation Oven" width="679" height="559" className="tool-image" />
                    <span className="tool-name">
                        <strong>PYD Life Sublimation Over - 25L - 1600W</strong><br />
                        Our main go to for full wrap mugs and other odd shaped items that won`t fully fit into the transpro.
                    </span>
                </article>
                <article className='tool-details'>
                    <img src="img/tools-we-use/xtool-d1.jpg" alt="XTool D1 (with extension arms)" width="679" height="405" className="tool-image" />
                    <span className="tool-name">
                        <strong>XTool D1 (with extension arms)</strong><br />
                        The Primary laser cutter and also used for engraving on larger items.
                    </span>
                </article>
                <article className='tool-details reverse'>
                    <img src="img/tools-we-use/xtool-f1.jpg" alt="XTool F1" width="1089" height="770" className="tool-image" />
                    <span className="tool-name">
                        <strong>XTool F1</strong><br />
                        The newer of the two laser machines and the one most used for engraving anything up to 6 inches due to it`s amazing speed.
                    </span>
                </article>
                <article className='tool-details'>
                    <img src="img/tools-we-use/bambu-p1s-3dprinter.jpg" alt="Bambu Labs P1S (with 4 color AMS)" width="750" height="750" className="tool-image" />
                    <span className="tool-name">
                        <strong>Bambu Labs P1S (with 4 color AMS)</strong><br />
                        The newest addition to the team. Will be heavily used for custom designs I am sure as we get to grips with it.
                    </span>
                </article>
            </section>
        </section>
    );
}