import { BsArrowUpRightSquare, BsFacebook, BsInstagram } from 'react-icons/bs';

export default function Footer() {
    return (
        <section className="footer">
            <div className="logo-copyright">
                <img 
                    src="/img/cattle-dog-creations-bandw-blacktext.png" 
                    width="80" 
                    height="*0" 
                    alt="Cattle Dog Creations" 
                    aria-describedby="Cattle Dog Creations Logo" 
                    style={{marginRight: '10px'}}
                />
                &copy; {(new Date().getFullYear())} Cattle Dog Creations
            </div>
            <article className="socials">
                <a 
                    href="https://www.facebook.com/kaitskorner757"
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                        <BsFacebook />
                </a>
                <a 
                    href="https://www.instagram.com/cattledogcreations757/"
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                        <BsInstagram />
                </a>
                <a 
                    href="https://www.etsy.com/shop/CDCreationsOnline" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    Etsy Store <BsArrowUpRightSquare className="external-link-icon" title="External Link to Cattle Dog Creations Online Etsy.com" />
                </a>
            </article>
        </section>
    );
}