import ContactForm from "../components/contact-form/contact-form";

import '../css/contact-us.css';

export default function Contact() {
    return (
        <section className="page-content-container">
            <h1 className="page-header">Contact Us</h1>
            <aticle className="contact-form">
                <img src="img/cattle-dog-creations-bandw-blacktext.png" alt="Cattle Dog Creations" width="300" height="300" />
                <ContactForm />
            </aticle>
        </section>
    );
}